import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Range from "../components/range"
import amazon from "../assets/img/amazon.png";

import eMarketing from "../assets/img/email-marketing.png"
import eManagement1 from "../assets/img/emanagement1.png"
import eManagement2 from "../assets/img/emanagement2.png"
import eManagement3 from "../assets/img/emanagement3.png"
import eManagement4 from "../assets/img/emanagement4.png"
import eManagement5 from "../assets/img/emanagement5.png"
import eManagement6 from "../assets/img/emanagement6.png"
import eManagement7 from "../assets/img/emanagement7.png"
import eManagement9 from "../assets/img/emanagement9.png"
import balance from "../assets/img/balance-time-money.png"
import achievement from "../assets/img/achievement-female.png"
import videoSearch from "../assets/img/video-search.png"
import cv from "../assets/img/cv-curriculum.png"
import maleMan from "../assets/img/male-man.png"
import business from "../assets/img/business-idea.png"
import lines from "../assets/img/backgr.png"
import inbox from "../assets/img/inbox.png";


import Teams from "../components/teams/email-team"
import SSS from "../components/sss/email-sss"
import Testimonial from "../components/testimonial"
import checkModal from "../assets/img/check-modal.png";

class EmailMarketing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: false,
            value: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        e.preventDefault();

        this.setState({ value: e.target.value });
    }
    showModal() {
        this.setState({ display: !this.state.display });
        document.querySelector('.check-modal').classList.add('active-modal');

        document.querySelector('#site').innerHTML = this.state.value;
    }
    closeModal() {
        this.setState({ display: !this.state.display })
        document.querySelector('.check-modal').classList.remove('active-modal');
    }
    handleSubmit(e) {
        e.preventDefault();
        const title = document.title.split('|', 1).toString();
        const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
            method: 'POST',
            body: JSON.stringify({
                "text_327649": title,
                "text_204202": document.querySelector('#site').value,
                "text_775908": document.querySelector('#name').value,
                "text_532192": document.querySelector('#email').value,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });
        response.then(res => {
            if (res.status === 200) {
                document.querySelector('.status').innerHTML = "Success!";
            }
            else {
                document.querySelector('.status').innerHTML = "Error";
            }
        });
    }
    render() {
        return (
            <Layout>
                <SEO title="Email Marketing" description="We improve the relationships between you and your target audience by informing them about your products and services, thus strengthen customer loyalty." />
                <div className="seo-service container">
                    <div className="seo-service__head">
                        <div className="col-md-6 noPadding">
                            <h1>Email Marketing</h1>
                            <span className="red-head">SERVICES</span>
                            <p>Send your newsletters effortlessly at 100 times more affordable prices.</p>
                            <img alt="alt text" style={{ maxWidth: '250px' }} src={amazon}></img>
                            <img alt="alt text" style={{ maxWidth: '250px' }} src={inbox}></img>
                        </div>
                        <div className="col-md-6 e-marketin_right" ><img style={{ width: '90%', marginTop: 50 }} alt="alt text" src={eMarketing} /> </div>
                    </div>
                </div>

                <div className="e-marketing container">
                    <div className="send-newsletter" style={{marginTop: 70}}>
                        <div style={{ textAlign: 'center' }}>
                            <h2>Send your newsletters effortlessly<br/> at 100 times more affordable prices.</h2>
                            <p>This service is especially favorable for the brands that are sending over 100,000 emails a month.</p>
                        </div>
                        <div className="row">
                            <Range />
                            <div className="col-md-6">
                                <div className="sendxs">
                                    <h3>Email Service Management</h3>
                                    <p>We reach your specific target audience on behalf of your brand and help you achieve your marketing goals.</p>
                                    <ul className="email-services_list-e">
                                        <li><span className="tick"></span> Email Template Design & Development </li>
                                        <li><span className="tick"></span> Domain DNS Setup For Email Marketing </li>
                                        <li><span className="tick"></span> Unlimited Subscriber Management </li>
                                        <li><span className="tick"></span> Entering the Inbox rate of 99.7% </li>
                                        <li><span className="tick"></span> Special Marketing Specialist </li>
                                        <li><span className="tick"></span> KVKK compliant infrastructure and IYS integration</li>
                                    </ul>
                                    <p className="risex">Prices starting from $90 per month.</p>
                                    <input type="text" className="pink-input" id="email-marketing-input" placeholder="Enter your e-mail address" value={this.state.value} onChange={this.handleChange} />
                                    <button className="red-button" id="email-marketing-button" onClick={(e) => this.showModal()}>Get your special offer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="email-management pt60">
                        <h2>Discover the features of <br/> our e-mail management service Sendy.</h2>
                        <div className="email-management_list pt60">
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <img alt="alt text" src={eManagement1} />
                                    <h3>Advanced Analysis and Insightful Reports</h3>
                                    <p>As part of our e-mail campaign services, you will receive insightful reports with a clear format from us. In these reports, you can see the results of each campaign and automatic replies. You can examine the data regarding opening, clicking, bouncing, complaints. The data is visualized by using graphics that would keep you on top of the campaign. You can also export your subscriber segments for retargeting.</p>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <img alt="alt text" src={eManagement2} />
                                    <h3>Email Campaign Auto Reply</h3>
                                    <p>You can send e-mail threads as frequently as you can to your subscribed audience in order to keep your brand relevant. </p>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <img alt="alt text" src={eManagement3} />
                                    <h3>Advanced List Segmentation</h3>
                                    <p>Our research results show that sending emails to targeted segments increases engagement and revenue by 25%. Our e-mail service provider Sendy allows you to create as many segments as you wish, according to the criteria that you set. </p>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <img alt="alt text" src={eManagement4} />
                                    <h3>Advanced Subscriber Management</h3>
                                    <p>With Sendy, you can easily manage lists and subscriptions. Sendy allows you to easily perform collective e-mail subscriptions, single and doubles opt-in, unsubscribe confirmation page, “thank you" and “goodbye” emails, API or subscription form to add users, and many other transactions regarding e-mail campaigns. </p>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <img alt="alt text" src={eManagement5} />
                                    <h3>Customizable Segmentation Areas</h3>
                                    <p>You can create custom fields to record more than your first name, surname and e-mail address. Not only you can get to know your subscribers better, but also you can use segmentation or personalization labels in your e-mail newsletters to offer them more personalized experiences to them.</p>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <img alt="alt text" src={eManagement6} />
                                    <h3>Bounce, Complaint<br/> & Unsubscribe Management</h3>
                                    <p>You can always keep your subscriber list up to date, as Sendy handles your drop-out rates, complaints and unsubscribe automatically in real-time as soon as your e-mail newsletter is sent to your subscriber's list.</p>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <img alt="alt text" src={eManagement6} />
                                    <h3>Send your newsletters 100 times cheaper</h3>
                                    <p>Sendy sends 10,000 emails to $1 over Amazon SES. With Sendy, you can avoid paying high fees to create email campaigns and enjoy the extremely fast delivered emails.</p>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <img alt="alt text" src={eManagement7} />
                                    <h3>Third-party <br/> integrations & Zapier</h3>
                                    <p>Sendy can be easily integrated with some of the most popular applications such as Wordpress, Magento, Joomla thanks to third party developers. It can also collaborate with Zapier, which enables integration with more than 1000 applications in the application directory.</p>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <img alt="alt text" src={eManagement9} />
                                    <h3>Regular Updates &<br/> Special Requests</h3>
                                    <p>Sendy is updated regularly according to the needs of its users. It is frequently developed according to your demands. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="email-marketing_testimonials">
                    <Testimonial />
                </div>

                <div className="hiring" style={{ padding: '50px 0px 0px' }}>
                    <img class="lines" alt="alt text" src={lines} />
                    <h2>Frequently Asked Questions</h2>
                    <SSS />
                </div>

                <div className="container manage-google ">
                    <h2 style={{ marginTop: 20 }}>Why choose us for email marketing<br/> campaign management?</h2>
                    <p className="manage-google_p">We invest in the relationships between you and your target audience by directly communicating <br/>with them on behalf of you, and highlight your brand with effective e-mail bulletins and newsletters.</p>
                    <div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={balance} />
                            <div>
                                <h3>Working with Professional Experts</h3>
                                <p>Our team of experts manages your email marketing account, not trainees or less experienced people.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={achievement} />
                            <div>
                                <h3>Creating Value</h3>
                                <p>We focus on high-impact work to increase your return on investment, and we constantly measure the KPIs we set for our goals related to your brand.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={videoSearch} />
                            <div>
                                <h3>Effective Communication Methods</h3>
                                <p>We arrange regular meetings with you to review the performance of social media campaigns and have discussions for new ideas.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={cv} />
                            <div>
                                <h3>A Very Special Report For You</h3>
                                <p>We regularly report the results of all performance indicators and the most important statistics for your brand.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={maleMan} />
                            <div>
                                <h3>Sendy Service Provider</h3>
                                <p>With Sendy’s variety of features, we can analyze and optimize the performance of your e-mail campaigns in real-time.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={business} />
                            <div>
                                <h3>Affordable Pricing</h3>
                                <p>The fee for our social media services is determined by your brand goals and the scope of work according to your expectations. Accordingly, a monthly prepayment is requested. Apart from this, no exceptional charges are requested.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="team" style={{ padding: 0 }}>
                    <h2 style={{ textAlign: 'center', marginBottom: 120 }}> Our email marketing experts <br/> who can help you.</h2>
                    <Teams />
                </div>

                <div className="check-modal">
                    <span onClick={(e) => this.closeModal()}>X</span>
                    <h2 className="form-head">Fill the form</h2>                       <p className="form-desc">Enter your information in the fields to get information, and we will get back to you as soon as possible.</p>
                    <div className="check-modal_container">
                        <form onSubmit={this.handleSubmit} >
                            <input type="text" name="text_204202" id="site" required  placeholder="Enter your website." />
                            <input type="text" id="name" required name="text_775908" placeholder="Enter your name" />
                            <input type="email" id="email" required  name="text_532192" value={this.state.value} onChange={this.handleChange} autoFocus={true} placeholder="Enter yor email" />
                            <button  >SEND</button>
                            <div className="status"></div>
                        </form>
                        <img alt="alt text" src={checkModal} />
                    </div>
                </div>

            </Layout>


        )
    }
}
export default EmailMarketing

