import React from "react"
import rightArrow from "../assets/img/right-arrow.png"
import leftArrow from "../assets/img/left-arrow.png"
import peliunShan from "../assets/img/peliun-shan.png"
import mert from "../assets/img/mert-haciosmanoglu.png"
import omer from "../assets/img/burak-omer-ogulturk.png"
import mustafa from "../assets/img/mustafa-karaman.png"

const Testimonial = () => (
  <div className="our-customers py60 container">
    <h2>
      Our clients' <br />
      comments about us.
    </h2>
    <p className="testimonP">
      You can find the comments of our clients <br />
      about the Flatart experience below.
    </p>
    <div className="testimonials">
      <input type="radio" name="testimon" id="t1" />
      <input type="radio" name="testimon" id="t2" />
      <input type="radio" name="testimon" id="t3" />
      <div className="testimonial" id="one">
        <p id="customer1">
          “Thank you for constantly trying to enlighten us on every step as well as enabling us to increase our sales through digital visibility.”
        </p>
        <div className="customer">
          <img src={mert} alt="peliun shan profile" />
          <div className="customer-name">
            <p>Mert Hacıosmanoğlu</p>
            <span>
              Liva Pattiserie <br />
              Board Member  
            </span>
          </div>
        </div>
        <label htmlFor="t3" className="pre">
          {" "}
          <img src={leftArrow} alt="left arrow" />
        </label>
        <label htmlFor="t2" className="nxt">
          {" "}
          <img src={rightArrow} alt="right arrow" />
        </label>
      </div>
      <div className="testimonial" id="two">
        <p id="customer1">
          “I would like to thank the Flatart team for determining the digital marketing strategy not by relying on common sense but from from real data. Their results-oriented approach helped our brand to grow successfully.”
        </p>
        <div className="customer">
          <img src={omer} alt="peliun shan profile" />
          <div className="customer-name">
            <p>Burak Ömer Oğultürk</p>
            <span>Cam Hotel CEO</span>
          </div>
        </div>
        <label htmlFor="t1" className="pre">
          <img src={leftArrow} alt="left arrow icon" />{" "}
        </label>
        <label htmlFor="t3" className="nxt">
          <img src={rightArrow} alt="right arrow icon" />{" "}
        </label>
      </div>
      <div className="testimonial" id="three">
        <p id="customer1">
          “What impressed me the most about Flatart was their effort to understand our expectations correctly. Understanding us correctly helped us achieve the results we wanted in a short time.”
        </p>
        <div className="customer">
          <img src={mustafa} alt="peliun shan profile" />
          <div className="customer-name">
            <p>Mustafa Karaman</p>
            <span>Kahveci Hacibaba CEO</span>
          </div>
        </div>
        <label htmlFor="t2" className="pre">
          {" "}
          <img src={leftArrow} alt="left arrow icon" />{" "}
        </label>
        <label htmlFor="t1" className="nxt">
          {" "}
          <img src={rightArrow} alt="right arrow icon" />{" "}
        </label>
      </div>
    </div>
  </div>
)

export default Testimonial
